@font-face {
  font-family: Main;
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindRegular.woff")
    format("woff");
}

body {
  margin: 0;
  font-family: Main, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
  padding-left: 0px;
}

h1 {
  font-size: 25px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 19px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 13px;
}

a {
  text-decoration: inherit;
  color: inherit;
  border-bottom: 1px dashed;
}

p {
  margin-top: 1px;
  margin-bottom: 1px;
}

button {
  margin-left: 2px;
  margin-right: 2px;
  background-color: #fff;
  border: 1px solid #a0aec0;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  vertical-align: middle;
}
button:hover {
  background-color: #f7fafa;
}

.m-pointer {
  cursor: pointer;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.icon-btn {
  font-size: 30px;
}
.icon-cover {
  width: 40px;
  height: 40px;
}

.di-icon {
  width: 50px;
  height: 50px;
}
.si-icon {
  width: 50px;
  height: 50px;
}

.badge {
  margin: 10px 10px 10px 10px;
}

/* https://velog.io/@tngusro/React-%EB%A7%88%EC%9A%B0%EC%8A%A4-hover%EC%8B%9C-%EB%A7%90%ED%92%8D%EC%84%A0%ED%88%B4%ED%8C%81 */
.footnote {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: blue;
  cursor: pointer;
  font-size: 9pt;
}
.footnote:hover {
  text-decoration: underline;
}
.footnote > .footnote-inner {
  display: none;
}
.footnote > .footnote-inner.visible {
  display: block;
}
.footnote > .footnote-inner {
  white-space: pre-line;
  position: absolute;
  bottom: 0;
  left: 0;
  color: black;
  background-color: white;
  border: black solid 1px;
  border-radius: 5px;
  font-size: medium;
  margin-bottom: 8px;
  padding: 10px 16px;
  width: max-content;
  z-index: 1;
  cursor: text;
  transform: translate(calc(-50% + 9px), -15px);
}
.footnote > .footnote-inner::before {
  border-color: white transparent;
  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: "";
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: calc(100% - 1.1px);
  width: 0;
  z-index: 1;
}
.footnote > .footnote-inner::after {
  border-color: black transparent;
  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: "";
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 100%;
  width: 0;
  z-index: 0;
}
